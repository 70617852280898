import React, { useState } from 'react';
import { Col, Hidden, Row, Container } from 'react-grid-system';
import Spacer from '../components/atoms/spacer';
import styled from 'styled-components';
import familj from '../images/panbox-familj.png';
import SEO from '../components/seo';
import useSnackbar from '../hooks/useSnackbar';
import { FULL_PAGE_HEIGHT } from '../services/sizeHelper';
import LoginCard from '../components/molecules/loginCard';
import { identifyWithBankID } from '../api';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import useAuth from '../hooks/useAuth';
import Routes from '../services/routes';
import { navigate } from 'gatsby';

const FamilyImage = styled.img`
  width: 100%;
  max-height: 100%;
`;

const StyledDiv = styled.div`
  min-height: ${FULL_PAGE_HEIGHT};
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Login = () => {
  const { verifyBankIDSignIn } = useAuth();
  const { IS_MOBILE } = useScreenClassHelper();
  const [loading, setIsLoading] = useState(false);
  const { handleMessage } = useSnackbar();

  const handleLogin = async (ssn: string) => {
    try {
      setIsLoading(true);
      const res = await identifyWithBankID(ssn);
      if (!res) return false;
      const isSignInSuccessful = await verifyBankIDSignIn();
      setIsLoading(false);
      if (isSignInSuccessful) {
        navigate(Routes.WELCOME);
      } else {
        handleMessage(
          'Något gick fel, vänligen försök igen eller kontakta oss om problemet kvarstår'
        );
      }
    } catch (e) {
      setIsLoading(false);
      handleMessage(
        'Något gick fel, vänligen försök igen eller kontakta oss om problemet kvarstår'
      );
    }
  };

  return (
    <StyledDiv>
      <SEO title="Logga in" />
      <Spacer spacing={IS_MOBILE ? 10 : 4} />
      <Container>
        <Row align="center">
          <Col>
            <Hidden xs sm>
              <FamilyImage alt="familj" src={familj} />
            </Hidden>
          </Col>
          <Col md="content">
            <Row justify="center">
              <Col width={IS_MOBILE ? '90%' : 540}>
                <LoginCard loading={loading} onLogin={handleLogin} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </StyledDiv>
  );
};

export default Login;
