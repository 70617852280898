import { getBankIDData, signInWithToken, submitDetailsForm } from '../api';
import { getSessionId, removeSessionId } from '../utils/localStorage';
import useFirebaseContext from './useFirebaseContext';

const useAuth = () => {
  const { fetchData } = useFirebaseContext();

  const verifyBankIDSignIn = async (): Promise<boolean> => {
    const sessionId = getSessionId();
    if (!sessionId) return false;
    const res = await getBankIDData(sessionId);
    if (!res) return false;
    const {
      givenName: firstName,
      surname: lastName,
      personalNumber: ssn
    } = res.userAttributes;

    const succ1 = await signInWithToken(ssn);
    if (!succ1) return false;
    const succ2 = await submitDetailsForm(ssn, {
      firstName,
      lastName,
      ssn
    });
    if (!succ2) return false;
    await fetchData();
    removeSessionId();
    return true;
  };

  return { verifyBankIDSignIn };
};

export default useAuth;
