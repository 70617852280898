import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Spacer from '../../components/atoms/spacer';
import styled, { keyframes } from 'styled-components';
import Container from '../../components/atoms/container';
import Typography from '../../components/atoms/typography';
import Button from '../../components/atoms/button';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
import Icon from '../../components/atoms/icon';
import bankid from '../../images/icons/bankid.png';
import TextInput from './textInput';

const LoginBox = styled.div`
  background-color: ${(props) => props.theme.colors.textSecondary};
  height: fit-content;
  width: fit-content;
  border-radius: 25px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.5);
  border-top: 8px solid lightgrey;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  display: block;
  margin: auto;
`;

const BankIdIcon = styled(Icon)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const Input = styled(TextInput)`
  background-color: ${({ theme }) => theme.colors.formBackground};
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  text-align: center;
  padding: 0 1rem;
`;

const InformationText = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 100;
  width: 80%;
`;

interface ILoginCard {
  loading: boolean;
  onLogin: (value: string) => void;
}

const LoginCard: React.FC<ILoginCard> = ({ loading, onLogin }) => {
  const { IS_MOBILE } = useScreenClassHelper();
  const [ssn, setSSN] = useState('');

  const handleSSNChange = (e: any) => {
    const value = e.target.value;
    if (ssn?.length === 7 && value.length === 8) {
      setSSN(`${value}-`);
    } else {
      setSSN(value);
    }
  };

  const onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onLogin(ssn);
    }
  };

  const isSSNValid = () => ssn.match(/^[0-9]{8}-[0-9]{4}$/);

  return (
    <LoginBox>
      <Container>
        <Spacer spacing={2} />
        <Row justify="center" align="center" direction="column">
          <Col md={12}>
            <BankIdIcon width={100} src={bankid} />
            <Spacer />
            {loading ? (
              <>
                <Typography align="center">
                  För att kunna logga in behöver du öppna Mobilt BankID på den
                  enhet som är kopplad till ditt angivna personnummer
                </Typography>
                <Spacer spacing={2} />
                <Spinner />
                <Spacer spacing={2} />
              </>
            ) : (
              <>
                <Input
                  placeholder="YYYYMMDD-XXXX"
                  value={ssn}
                  onChange={handleSSNChange}
                  disabled={loading}
                  onKeyUp={onKeyUp}
                />
                <Spacer spacing={2} />
                <StyledButton
                  onClick={() => onLogin(ssn)}
                  primary
                  isMobile={IS_MOBILE}
                  isLoading={loading}
                  disabled={!isSSNValid()}
                >
                  Logga in med BankID
                </StyledButton>
              </>
            )}
          </Col>
        </Row>
        <Spacer spacing={2} />
        <Row justify="center">
          <InformationText>
            Genom att fortsätta godkänner jag panBox Användarvillkor och
            bekräftar att jag läst panBox Dataskyddsinformation.
          </InformationText>
        </Row>
        <Spacer spacing={3} />
      </Container>
    </LoginBox>
  );
};

export default LoginCard;
