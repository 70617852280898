import React from 'react';
import styled from 'styled-components';

const StyledTextInput = styled.input`
  height: 40px;
  background-color: ${(props) => props.theme.colors.textInputBackground};
  color: ${(props) => props.theme.colors.textPrimary};
  border: none;
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholderPrimary};
  }
`;

const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (
  props
) => <StyledTextInput {...props} />;

export default Input;
