import React from 'react';
import Input from '../atoms/input';
import { ITextInput } from '../atoms/interfaces';
import Typography from '../atoms/typography';
import TextArea from '../atoms/textArea';
import styled from 'styled-components';

const StyledCheckbox = styled.input`
  margin-right: 1rem;
`;

const TextInput: React.FC<ITextInput> = ({
  textArea,
  label,
  errorMsg,
  ...props
}) => {
  return (
    <>
      {props.type === 'checkbox' && (
        <StyledCheckbox type="checkbox" {...props} />
      )}
      {label && (
        <Typography color={props.color} variant="label" htmlFor={props.name}>
          {label}
        </Typography>
      )}
      {textArea && <TextArea {...props} />}
      {props.type !== 'checkbox' && !textArea && <Input {...props} />}
      {errorMsg && <Typography variant="error">{errorMsg}</Typography>}
    </>
  );
};
export default TextInput;
