import React from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea`
  background-color: ${(props) => props.theme.colors.textInputBackground};
  color: ${(props) => props.theme.colors.textPrimary};
  border: none;
  width: 100%;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholderPrimary};
  }
`;

const TextArea: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = (
  props
) => <StyledTextArea {...props} />;

export default TextArea;
